<template>
	<v-card
		color="green accent-1">
		<v-card-title class="justify-center">
			Ура 🎉
		</v-card-title>
		<v-card-text>
			<p class="subtitle-1 text-center">Твій рівень:</p>
			<p class="display-1 amber--text text-center">
				<b>{{ level }}</b>
			</p>
			<p class="title success--text text-center">
				<b>{{ result ? result.result : '' }}%</b>
			</p>
			Хочеш підвищити свій результат?
			Тоді реєструйся на поглиблений курс підготовки до Єдиного вступного іспиту (ЄВІ) в Магістратуру з англійської мови (ЗНО-Магістратура)
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn
				href="https://magistracy.brames.com.ua"
				target="blank"
				color="success"
				outlined>
				Реєструйся на курс 🤓
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
export default {
	props: {
		result: {
			type: Object,
			required: true,
		},
	},
	computed: {
		level() {
			const result = this.result && this.result.result
			if (result)
				if (result >= 74) return 'Upper Intermediate'
				if (result >= 52) return 'Intermediate'
				if (result >= 36) return 'Pre-Intermediate'
				if (result >= 16) return 'Elementary'
			return 'Не визначено'
		},
	},
}
</script>