<template>
	<answer-block
		:answers="question.answers"
		:show="question.show_result"
		@input="e => $emit('input', e)"/>
</template>

<script>
import AnswerBlock from './Answers/AnswerBlock'

export default {
	components: {
		AnswerBlock,
	},
	props: {
		question: {
			type: Object,
			required: true,
		},
	},
}
</script>