<template>
	<v-container>
		<v-btn
			tag="label"
			:color="color"
			:depressed="depressed"
			class="answer d-inline-block pt-3 pb-3 pl-3 text-none"
			:ripple="true"
			large>
			<span>
				<slot />
			</span>
			<input
				v-model="computedValue"
				type="checkbox"
				:disabled="disabled"
				:required="required"
				:name="name"
				:value="nativeValue"/>
		</v-btn>
	</v-container>
</template>

<script>
export default {
	props: {
		value: [String, Number, Boolean, Function, Object, Array, Symbol],
		nativeValue: [String, Number, Boolean, Function, Object, Array, Symbol],
		disabled: Boolean,
		required: Boolean,
		depressed: Boolean,
		name: String,
		size: String,
		isCorrect: {
			type: Boolean,
			default: false,
		},
		isShowResult: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			newValue: this.value,
		}
	},
	computed: {
		computedValue: {
			get() {
				return this.newValue
			},
			set(value) {
				this.newValue = value
				this.$emit('input', value)
			},
		},
		checked() {
			if (Array.isArray(this.newValue)) {
				return this.newValue.indexOf(this.nativeValue) >= 0
			}
			return this.newValue === this.nativeValue
		},
		color() {
			if (this.isShowResult) {
				if (this.checked) {
					if (this.isCorrect) return 'success'
					else return 'error'
				} else {
					if (this.isCorrect) return 'primary'
				}
				return ''
			} else {
				return this.checked ? 'amber' : ''
			}
		},
	},
	watch: {
		/**
		 * When v-model change, set internal value.
		 */
		value(value) {
			this.newValue = value
		},
	},
}
</script>

<style scoped>
.answer {
	cursor: pointer;
	white-space: normal;
	height: auto !important;
	letter-spacing: normal !important;
}

.answer [type="checkbox"] {
	display: none;
}
</style>