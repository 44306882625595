<template>
	<v-container pa-0>
		<answer
			v-for="(answer, key) in answers"
			:key="answer.id"
			v-model="userAnswers"
			:nativeValue="answer.id"
			:disabled="show"
			:depressed="show"
			:is-correct="answer.is_correct"
			:is-show-result="show"
			@input="$emit('input', { result, userAnswers })">
			<v-chip
				small
				outlined
				class="pa-0 mr-2 justify-center"
				style="width: 1.5rem; border-radius: 2px;">
				{{ indexes[key] }}
			</v-chip>
			{{ answer.text }}
			<v-img
				v-for="(image, key) in answer.images"
				:key="key"
				:src="image.original_url"
				contain
				max-width="300"
				max-height="200"></v-img>
		</answer>
	</v-container>
</template>

<script>
import Answer from './Answer'

export default {
	components: {
		Answer,
	},
	props: {
		answers: {
			type: Array,
			required: true,
		},
		show: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		userAnswers: [],
		indexes: [
			'А', 'Б', 'В', 'Г', 'Д', 'Е',
		],
	}),
	computed: {
		correctAnswers() {
			return this.answers
				.filter(item => item.is_correct)
				.map(item => item.id)
		},
		result() {
			return this.deepEqualArr(this.userAnswers, this.correctAnswers)
		},
	},
}
</script>