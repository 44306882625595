<template>
	<v-container px-0>
		<template
			v-for="(answer, index) in answers">
			<v-text-field
				v-model.trim="userAnswers[index]"
				:key="answer.id"
				hint="використовуй крапку в якості десяткового розділювача, наприклад: 2.5"
				:disabled="show"
				:depressed="show"
				filled
				dense
				@input="$emit('input', { result, userAnswers })">
				{{ answers.text }}
			</v-text-field>
			<v-alert
				v-if="show"
				:key="`correct-${answer.id}`"
				:type="result ? 'success' : 'error'"
				text
				dense>
				Правильна відповідь: {{ correctAnswers[index] }}
			</v-alert>
		</template>
	</v-container>
</template>

<script>
import { cloneDeep } from 'lodash'

export default {
	props: {
		answers: {
			type: Array,
			required: true,
		},
		show: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		userAnswers: [],
	}),
	computed: {
		correctAnswers() {
			return this.answers
				.filter(item => item.is_correct)
				.map(item => item.text)
		},
		result() {
			return this.deepEqualArr(
				cloneDeep(this.userAnswers),
				cloneDeep(this.correctAnswers))
		},
	},
}
</script>