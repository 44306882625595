<template>
	<!-- <v-container> -->
		<div>
			<v-layout v-for="answer in commonAnswers"
				:key="answer.id"
				wrap
				class="pa-2 mb-10 align-center">
				<v-flex xs12 sm6>
					{{ answer.text }}
					<v-img
						v-for="(image, key) in answer.images"
						:key="key"
						:src="image.original_url"
						contain
						max-width="300"
						max-height="200"></v-img>
				</v-flex>
				<v-flex xs12 sm6>
					<v-select
						v-model="userAnswers[answer.id]"
						:items="pairAnswers"
						:disabled="show"
						item-value="id"
						item-text="text"
						outlined
						dense
						hide-details
						@input="check"/>
				</v-flex>
			</v-layout>
		</div>
	<!-- </v-container> -->
</template>

<script>
export default {
	props: {
		answers: {
			type: Array,
			required: true,
		},
		show: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		userAnswers: {},
		result: false,
	}),
	computed: {
		commonAnswers() {
			return this.answers
				.filter(item => item.correct_pair)
		},
		pairAnswers() {
			return this.answers
				.filter(item => !item.correct_pair)
		},
		correctAnswers() {
			return this.commonAnswers.reduce((current, next) => {
				return { ...current, ...{ [next.id]: next.correct_pair } }
			}, {})
		},
	},
	methods: {
		populateUserAnswers() {
			return this.answers
				.filter(item => item.correct_pair)
				.forEach(item => this.userAnswers[item.id] = null)
		},
		check() {
			this.result = this.deepEqualObj(this.userAnswers, this.correctAnswers)
			const { result, userAnswers } = this
			return this.$emit('input', { result, userAnswers })
		},
	},
	mounted() {
		this.populateUserAnswers()
	},
}
</script>