<template>
	<answers-paired
		:answers="question.answers"
		:show="question.show_result"
		@input="e => $emit('input', e)"/>
</template>

<script>
import AnswersPaired from '../Answers/AnswersPaired'

export default {
	components: {
		AnswersPaired,
	},
	props: {
		question: {
			type: Object,
			required: true,
		},
	},
	computed: {

	},
}
</script>