<template>
	<answers-singled
		:answers="question.answers"
		:show="question.show_result"
		@input="e => $emit('input', e)">
	</answers-singled>
</template>

<script>
import AnswersSingled from '../Answers/AnswersSingled'

export default {
	components: {
		AnswersSingled,
	},
	props: {
		question: {
			type: Object,
			required: true,
		},
	},
}
</script>